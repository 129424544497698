<!--  -->
<template>
  <div style="width: 100%;height: 100%;">
    <wordcloud
      :data="echartsData"
      nameKey="name"
      :imageShape="imageShape"
      :fontSize="[16,24]"
      valueKey="value"
      color="Accent"
      font="led"
      :wordClick="wordClickHandler"
      :showTooltip="false"
      >
    </wordcloud>
  </div>
</template>

<script>
  import wordcloud from 'vue-wordcloud'
  export default {
    data() {
      return {
        imageShape:require('../../assets/images/login/login_logo.png'),
        echartsData: []
      };
    },
    components:{
      wordcloud
    },
    props:['id'],
    mounted: function () {
      this.wordCloud(0)
    },
    methods: {
      //点击
      wordClickHandler(name, value, vm) {
        this.$emit('wordValue',name)
      },
      //词云
      wordCloud(item){
        let name = item == 0 || item == 1 ? 'reviewCase' :  item == 2  ? 'judgementDocument' : item == 3 ? 'courtNotice' : ''
        this.$axios.get('/trademark/iphouseApi/wordCloud/' + name).then(({data}) => {
          if(data.code == 0){
            this.echartsData = data.data
          }else {
            this.$message.error('查询失败');
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish
            // this.$message.error('查询失败');
            console.log(error);
          })
      }
    }

  }

</script>
<style lang='less' >
  div.tooltip{
    width: auto;
  }
</style>
