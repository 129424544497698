<template>
  <div class="index_wrap">
    <h1 class="h_none">商标查询|专利翻译|知识产权管理系统-蓝灯鱼智能检索平台</h1>
    <h2 class="h_none">蓝灯鱼,lanternfish,蓝灯鱼商标,蓝灯鱼翻译,蓝灯鱼专利,AI,人工智能</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <div style="height: 90px"></div>

    <div  class="search_input">
      <img class="fa" src="../../assets/images/informationupload/fa.png" alt="">
      <ul  class="tabList">
        <li :style="{background: (id == 0 || id == 1) && item.active ? '#D9DFF2' :  id == 2 && item.active  ? '#DFDFF7' : id == 3 && item.active? '#DADAE7' : ''}" @click="tabEvent(item)" :class="{active: item.active}" v-for="(item,index) in tabList">
          <span>{{item.text}}</span>
        </li>
      </ul>
      <div :style="{background: id == 0 || id == 1 ? '#D9DFF2' :  id == 2  ? '#DFDFF7' : id == 3 ? '#DADAE7' : '',height: dialogTableVisible ? '191px' : '138px'}" class="announcement_box">
        <div :class="['announcement_input',{'active': id == 0 || id == 1}]">
          <div class="issue">全文检索</div>
          <el-input  @keyup.enter.native="currentPage = 1;searchEvent()"  placeholder="输入商标名称、申请人、代理机构等关键词查询，多个关键词逗号（,）分隔输入…" class="input" v-model="text" type="text"/>
          <div @click="currentPage = 1;searchEvent()" class="query-btn"></div>
        </div>
        <div v-if="id == 0 || id == 1"  class="gaoji">
          <span @click="isKeyword = true;dialogTableVisible = false" :class="[{'active': isKeyword}]">
            关键词检索
          </span>
          <span  @click="isKeyword = false;gaoji()" :class="[{'active': !isKeyword}]">
            高级检索
          </span>
        </div>
      </div>
    </div>
    <!--    商标评审-->
    <div :style="{background: id == 0 || id == 1 ? '#D9DFF2' :  id == 2  ? '#DFDFF7' : id == 3 ? '#DADAE7' : ''}" v-if="id == 1 && trademarkTable.length && dialogTableVisible == false" class="search_content">
      <ul >
        <li style="display: flex" @click="detail(item)" v-for="(item,index) in trademarkTable" :key="index">
          <div style="width: 183px;height: 112px;margin-right: 23px;border: 1px solid #cdccd2;border-radius: 8px;overflow: hidden;display: flex;align-items: center">
            <img style="width: 100%;max-height: 112px" :src="'https://tmimage.lanternfish.cn' + item.imgUrl" alt="">
          </div>
          <div style="width: 800px">
            <p v-html="item.title"></p>
            <p>
              <span v-html="item.caseNo"></span>
              <span v-html="item.judgeDate"></span>
            </p>
            <p v-html="item.summary">
            </p>
          </div>
        </li>
      </ul>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
    <!--    裁判文书-->
    <div :style="{background: id == 0 || id == 1 ? '#D9DFF2' :  id == 2  ? '#DFDFF7' : id == 3 ? '#DADAE7' : ''}" v-if="id == 2 && trademarkTable.length" class="search_contents">
      <ul >
        <li @click="detail(item)" v-for="(item,index) in trademarkTable" :key="index">
          <p v-html="item.title"></p>
          <p>
            <span v-html="item.caseNo"></span>
            <span v-html="item.judgeDate"></span>
          </p>
          <p>
            <span>案件性质：<i v-html="item.caseNature"></i></span>
            <span>
              案由：
              <i :key="indexs" v-for="(items,indexs) in item.caseCause"><i v-html="items"></i> {{indexs != item.caseCause.length -1 ? '、' : ''}}</i>
            </span>
            <span>案件地区：<i v-html="item.caseArea"></i></span>
            <span>裁判结果：<i v-html="item.judgementResult"></i></span>
          </p>
          <p>
            <span>裁判日期：<i v-html="item.judgeDate"></i></span>
            <span class="role_item">
              当事人：
              <i :key="indexs" v-for="(items,indexs) in item.role.litigant"><i v-html="items"></i>{{indexs != item.role.litigant.length - 1 ? '、' : ''}}</i>
            </span>
          </p>
        </li>
      </ul>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
    <!--    开庭公告-->
    <div :style="{background: id == 0 || id == 1 ? '#D9DFF2' :  id == 2  ? '#DFDFF7' : id == 3 ? '#DADAE7' : ''}" v-if="id == 3 && trademarkTable.length > 0" class="search_content_announcement">
      <ul >
        <li @click="detail(item)" v-for="(item,index) in trademarkTable" :key="index">
          <p v-html="item.title"></p>
          <p>
            <span v-html="item.causeOfAction"></span>
            <span v-html="item.courtTime"></span>
          </p>
          <p v-html="item.rawData">
          </p>
        </li>
      </ul>
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
    <div :style="{background: id == 0 || id == 1 ? '#D9DFF2' :  id == 2  ? '#DFDFF7' : id == 3 ? '#DADAE7' : ''}" style="display: flex;align-items: center;justify-content: center;padding: 0" v-if="(id == 0 || trademarkTable.length == 0) && dialogTableVisible == false" class="search_content">
      <wordcloud @wordValue="wordValue" :id="id" ref="wordcloud"></wordcloud>
    </div>
    <div class="trademarkAdvanced"  v-if="dialogTableVisible">
<!--      <el-input placeholder="请输入内容" v-model="input3" class="input-with-select">-->
<!--        <span slot="prepend">全文检索</span>-->
<!--        <el-button slot="append" icon="el-icon-search" @click="sbSearch"></el-button>-->
<!--      </el-input>-->
      <ul style="margin-top: 5px">
        <!--          裁决结果-->
        <li style="height: 125px">
          <div  class="labelT">
            裁决结果
          </div>
          <div  class="content">
            <!--            驳回复审-->
            <el-select style="margin-right: 20px;margin-bottom: 13px" size="medium"  v-model="value" placeholder="驳回复审">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <!--            不予注册复审-->
            <el-select style="margin-right: 20px;margin-bottom: 13px"  size="medium" v-model="value1" placeholder="不予注册复审">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <!--            无效宣告请求-->
            <el-select size="medium" v-model="value2" placeholder="无效宣告请求">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <!--            无效宣告复审-->
            <el-select style="margin-right: 20px" size="medium" v-model="value3" placeholder="无效宣告复审">
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <!--            无效宣告复审-->
            <el-select size="medium" v-model="value4" placeholder="撤销复审">
              <el-option
                v-for="item in options4"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </li>
        <!--          法律依据-->
        <li style="height: 70px">
          <div  class="labelT">
            法律依据
          </div>
          <div  class="content">
            <el-input size="medium" v-model="basis" placeholder="例:商标法第三十五条"></el-input>
          </div>
        </li>
        <!--          涉及商标-->
        <li style="height: 177px">
          <div class="labelT">
            涉及商标
          </div>
          <div style="padding-right: 20px" class="content">
            <!--            商标分类-->
            <el-select  style="margin-right: 20px;margin-bottom: 13px" size="medium" v-model="monitoringCategory" filterable multiple collapse-tags clearable placeholder="商标分类" class="el-select-long-officialt" @change="selectIntcls">
              <el-option v-for="(item,index) in announcementList" :key="index" :label="item.label" :value="item.label">
              </el-option>
            </el-select>
            <!--            商标类型-->
            <el-select style="margin-right: 5px;margin-bottom: 2px" size="medium" v-model="value5" placeholder="商标类型">
              <el-option
                v-for="item in options5"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <br/>
            <!--              申请/注册号-->
            <div style="margin-bottom: 13px;margin-right: 20px" class="block">
              <span  class="demonstration">注册号</span>
              <el-input size="medium" class="_input" v-model="registration" placeholder="字母、数字"></el-input>
            </div>
            <!--              申请/注册人-->
            <div style="margin-bottom: 13px;margin-right: 20px" class="block">
              <span  class="demonstration">申请人</span>
              <el-input size="medium" class="_input"  v-model="registrant" placeholder="输入关键词"></el-input>
            </div>
            <!--              商标名称-->
            <div style="margin-right: 5px;margin-bottom: 13px" class="block">
              <span class="demonstration">商标名称</span>
              <el-input size="medium" class="_input" v-model="tradeName" placeholder="输入关键词"></el-input>
            </div>
            <!--              代理机构-->
            <div style="margin-right: 20px" class="block">
              <span class="demonstration">代理机构</span>
              <el-input size="medium" class="_input" v-model="agency" placeholder="输入关键词"></el-input>
            </div>
            <!--              申请时间-->
            <div style="margin-top: 2px" class="block active">
              <span class="demonstration">申请时间</span>
              <el-date-picker
                size="medium"
                v-model="applicationTime"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
        </li>
        <!--          当 事 人-->
        <li style="height: 75px">
          <div  class="labelT">
            当 事 人
          </div>
          <div style="display: flex;align-items: center;padding-top: 0px" class="content">
            <!--              申请/注册号-->
            <div style="margin-right: 20px" class="block">
              <span class="demonstration">申请人</span>
              <el-input size="medium" class="_input" v-model="proposer" placeholder="输入关键词"></el-input>
            </div>
            <!--              申请/注册人-->
            <div style="margin-right: 20px" class="block">
              <span class="demonstration">被申请人</span>
              <el-input size="medium" class="_input"  v-model="respondent" placeholder="输入关键词"></el-input>
            </div>
            <!--              代理机构-->
            <div  class="block">
              <span class="demonstration">代理机构</span>
              <el-input size="medium" class="_input" v-model="representative" placeholder="输入关键词"></el-input>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="login_dialog"
               :before-close="closeEvent">
      <login v-if="dialogVisible" @loginDialog="loginDialog" :dialog-close="dialogClose" @dialogCloseBool="dialogCloseBool"></login>
    </el-dialog>
    <footers></footers>
  </div>
</template>

<script>
  import footers from '@/components/common/footers'
  import wordcloud from '@/components/chart/wordcloud'
  import login from "@/components/common/login";
  export default {
    name: 'index',
    metaInfo: {
      title: '商标查询|专利翻译|知识产权管理系统-蓝灯鱼智能检索平台', // set a title
      meta: [{                 // set meta
        name: 'keywords',
        content: '蓝灯鱼,lanternfish,蓝灯鱼商标,蓝灯鱼翻译,蓝灯鱼专利,AI,人工智能,知识产权,蓝灯鱼招聘,蓝灯鱼地址,关于蓝灯鱼'
      }, {                 // set meta
        name: 'description',
        content: '蓝灯鱼是提供商标查询，专利检索，专利翻译服务的知识产权智能检索平台，应用人工智能技术，已发布商标图形查询，专利翻译，CPC官文提取工具，知识产权管理系统等系列产品。'
      }],
    },
    data () {
      return {
        dialogVisible: false,
        dialogClose: false,
        isKeyword: true,
        idFlag: true,
        //是否输入关键词
        keyFlag: false,
        //高级查询
        isflag: false,
        //高级查询内容
        input3:"",
        //
        id: 0,
        //普通搜索内容
        text: '',
        //代理机构
        representative:'',
        //被申请人
        respondent:'',
        //申请人
        proposer:'',
        //注册号
        registration:'',
        ///申请、注册人
        registrant:'',
        //代理机构
        agency:'',
        //商标名称
        tradeName:'',
        //申请时间
        applicationTime:[],
        //监控类型
        announcementList: [],
        //监控类别
        monitoringCategory: [],
        //法律依据
        basis:'',
        options: [{
          value: '驳回复审-不限',
          label: '不限'
        }, {
          value: '驳回复审-注册申请部分予以驳回',
          label: '注册申请部分予以驳回'
        }, {
          value: '驳回复审-注册申请予以初步审定',
          label: '注册申请予以初步审定'
        }, {
          value: '驳回复审-注册申请予以驳回',
          label: '注册申请予以驳回'
        }],
        value: '',
        options1: [{
          value: '不予注册复审-不限',
          label: '不限'
        }, {
          value: '不予注册复审-被异议商标不予核准注册',
          label: '被异议商标不予核准注册'
        }, {
          value: '不予注册复审-被异议商标部分予以核准注册',
          label: '被异议商标部分予以核准注册'
        }, {
          value: '不予注册复审-被异议商标予以核准注册',
          label: '被异议商标予以核准注册'
        }],
        value1: '',
        options2: [{
          value: '无效宣告请求-不限',
          label: '不限'
        }, {
          value: '无效宣告请求-争议商标予以无效宣告',
          label: '争议商标予以无效宣告'
        }, {
          value: '无效宣告请求-争议商标予以维持',
          label: '争议商标予以维持'
        }, {
          value: '无效宣告请求-争议商标部分予以维持',
          label: '争议商标部分予以维持'
        }],
        value2: '',
        options3: [{
          value: '无效宣告复审-不限',
          label: '不限'
        }, {
          value: '无效宣告复审-复审商标予以无效宣告',
          label: '复审商标予以无效宣告'
        }, {
          value: '无效宣告复审-复审商标部分予以维持',
          label: '复审商标部分予以维持'
        }, {
          value: '无效宣告复审-复审商标予以维持',
          label: '复审商标予以维持'
        }],
        value3: '',
        options4: [{
          value: '撤销复审-不限',
          label: '不限'
        }, {
          value: '撤销复审-复审商标部分予以维持',
          label: '复审商标部分予以维持'
        }, {
          value: '撤销复审-复审商标予以撤销',
          label: '复审商标予以撤销'
        }, {
          value: '撤销复审-复审商标予以维持',
          label: '复审商标予以维持'
        }],
        value5: '',
        options5: [{
          value: '不限',
          label: '不限'
        }, {
          value: '申请商标',
          label: '申请商标'
        }, {
          value: '被异议商标',
          label: '被异议商标'
        }, {
          value: '争议商标',
          label: '争议商标'
        }, {
          value: '复审商标',
          label: '复审商标'
        }, {
          value: '引证商标',
          label: '引证商标'
        }],
        value4: '',
        dialogTableVisible: false,
        currentPage: 1,
        pageSize: 5,
        trademarkTable:[],
        total: 0,
        tabList: [
          {
            id: 1,
            text: '商标评审',
            active: true
          },
          {
            id: 2,
            text: '裁判文书',
            active: false
          },
          {
            id: 3,
            text: '开庭公告',
            active: false
          }
        ]
      }
    },
    created(){
      this.announcementList.push({
        value: "",
        label: "全部",
      });
      for(let i = 1; i < 46; i++) {
        let num = i > 9 ? "" + i : "0" + i;
        this.announcementList.push({
          value: i,
          label: num,
        })
      }
    },
    mounted() {
    },
    components: {
      footers,
      wordcloud,
      login
    },
    watch:{
      text(newValue,oldValue){
        if (newValue && newValue.indexOf("，") != -1) this.text = newValue.replace(/，/g, ",")
      },
      dialogTableVisible(newValue,oldValue){
        if(newValue){
          this.input3 = ''
            this.value = ''
            this.value1 = ''
            this.value2 = ''
            this.value3 = ''
            this.value4 = ''
            this.basis = ''
            //涉及商标
            this.monitoringCategory = [],
             this.value5 = ''
            this.registration = ''
            this.registrant = ''
            this.tradeName = ''
             this.agency = ''
           this.applicationTime = [],
           this.proposer = ''
            this.respondent = ''
            this.representative = ''
        }
      }
    },
    methods: {
      // 登录
      loginEvent () {
        this.dialogVisible = true;
      },
      // 登录弹层隐藏
      loginDialog (data) {
        this.dialogVisible = data;
        // this.noticeEvent();
      },
      dialogCloseBool (data) {
        this.dialogClose = data;
      },
      closeEvent (done) {
        this.dialogClose = true;
        done();
      },
      //wordValue
      wordValue(value){
        this.text = value
      },
      //回车键按下事件
      keyupEven(){
        this.keyFlag = true
      },
      //高级查询
      async sbSearch(){
        // this.currentPage = 1
        this.isflag = true
        this.id = 1
        this.dialogTableVisible = false
        await this.$axios.post('/trademark/iphouseApi/reviewCaseAdvanced',{
          "content": this.text,
          "judgeResult": [this.value ,this.value1 ,this.value2 ,this.value3 ,this.value4].length > 0 ? [this.value ,this.value1 ,this.value2 ,this.value3 ,this.value4].filter(item => item).join(";") :undefined,
          "legalBasis": this.basis,
          //涉及商标
          "refCategory":this.monitoringCategory.join(";"),
          "refTrademarkType": this.value5,
          "refApplyNoOrRegNo": this.registration,
          "refApplication": this.registrant,
          "refTrademarkName": this.tradeName,
          "refAgency": this.agency,
          "refApplyStartDate":this.applicationTime[0],
          "refApplyEndDate": this.applicationTime[1],
          "applicant": this.proposer,
          "respondent": this.respondent,
          "agency": this.representative,
          "pageNo":this.currentPage,
          "pageSize":this.pageSize,
        }).then(({data}) => {
          if(data.code == 0){
            this.trademarkTable = data.data
            this.total = data.total
            if(data.data.length == 0){
              this.$message('暂无结果');
            }
          }else if(data.code == 10003){
            this.trademarkTable = []
            this.$message.error(data.msg);
          }else {
            this.trademarkTable = []
            this.$message.error('查询失败');
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            this.$message.error('查询失败');
            this.trademarkTable = []
            console.log(error);
          })
      },
      //普通查询
      searchEvent(){
        // this.currentPage = 1
        if(this.tool.getCookie("auth")){
        }else {
          this.dialogVisible = true
          return;
        }
        if(!this.text){
          this.$message.error('请您在全文检索框内输入关键词后进行检索');
          return;
        }
        this.isflag = false
        if(this.id == 0 || this.id == 1){
          this.id = 1
          if(this.isKeyword){
            this.getTramketList()
          }else {
            this.sbSearch()
          }
        }else if(this.id == 2){
          this.getAdjudicative()
        }else if(this.id == 3){
          this.getAnnouncement()
        }
      },
      detail(item){
        if(this.id == 0 || this.id == 1){
          this.judgementDocumentDetail(item,0)
        }else if(this.id == 2){
          this.judgementDocumentDetail(item,1)
        }else if(this.id == 3){
          this.judgementDocumentDetail(item,2)
        }
      },
      //获取裁判文书详情
      judgementDocumentDetail(item,value){
        let routeUrl = this.$router.resolve({
          path: "/judgementDocumentDetail",
          query: {
            id: item.cid,
            flag: value,
            keyword: this.text
          }
        });
        window.open(routeUrl.href, "_blank");
      },
      selectIntcls(v) {
        if(v.includes('全部')) {
          this.monitoringCategory = this.announcementList.filter(i => i.label != "全部").map(i => i.label)
        } else {
          this.monitoringCategory = v
        }
      },
      gaoji(){
        if(this.tool.getCookie("auth")){
        }else {
          this.dialogVisible = true
          return;
        }
        this.currentPage = 1
        this.dialogTableVisible = true
      },
      //开庭公告
      async getAnnouncement(){
        await this.$axios.post('/trademark/iphouseApi/courtNotice', {
          "page": this.currentPage,
          "pageSize": this.pageSize,
          "keyword": this.text
        }).then(({data}) => {
          if(data.code == 0){
            this.trademarkTable = data.data
            this.total = data.total
            if(data.data.length == 0){
              this.$message('暂无结果');
            }
          }else if(data.code == 10003){
            this.trademarkTable = []
            this.$message.error(data.msg);
          }else {
            this.trademarkTable = []
            this.$message.error('查询失败');
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            this.$message.error('查询失败');
            this.trademarkTable = []
            console.log(error);
          })
      },
      //裁判文书
      async getAdjudicative(){
        await this.$axios.post('/trademark/iphouseApi/judgementDocument', {
          "page": this.currentPage,
          "pageSize": this.pageSize,
          "keyword": this.text
        }).then(({data}) => {
          if(data.code == 0){
            this.trademarkTable = data.data
            this.total = data.total
            if(data.data.length == 0){
              this.$message('暂无结果');
            }
          }else if(data.code == 10003){
            this.trademarkTable = []
            this.$message.error(data.msg);
          }else {
            this.trademarkTable = []
            this.$message.error('查询失败');
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            this.$message.error('查询失败');
            this.trademarkTable = []
            console.log(error);
          })
      },
      //商标评审
      async getTramketList(){
        await this.$axios.post('/trademark/iphouseApi/reviewCase', {
          "page": this.currentPage,
          "pageSize": this.pageSize,
          "keyword": this.text
        }).then(({data}) => {
          if(data.code == 0){
            this.trademarkTable = data.data
            this.total = data.total
            if(data.data.length == 0){
              this.$message('暂无结果');
            }
          }else if(data.code == 10003){
            this.trademarkTable = []
            this.$message.error(data.msg);
          }else {
            this.trademarkTable = []
            this.$message.error('查询失败');
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            this.$message.error('查询失败');
            this.trademarkTable = []
            console.log(error);
          })
      },
      tabEvent(item){
        this.tabList.map(item => {
          item.active = false
          return item
        })
        this.isKeyword = true
        this.dialogTableVisible = false
        this.id = item.id
        item.active = true
        this.total = 0
        this.trademarkTable = []
        this.$refs.wordcloud.wordCloud(item.id)
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.isflag ? this.sbSearch() : this.searchEvent()
      }
    }
  }
</script>

<style scoped lang="less">
  .index_wrap {
    background: #F1F3FA;
    /deep/.specialContent{
      color: red;
    }

    .search_input{
      position: relative;
      height: 258px;
      width: 1200px;
      margin: 0 auto;
      padding: 37px 65px 0 74px;
      background: #e6ebf7;
      box-sizing: border-box;
      .fa {
        width: 248px;
        height: auto;
        position: absolute;
        right: 0;
        top: -10px;
      }
      .tabList{
        display: flex;
        li{
          cursor: pointer;
          width: 102px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.active{
            font-weight: 500;
            color: #1A3DBF;
            background: #DFDFF7;
            border-radius: 8px 8px 0px 0px;
            span{
              position: relative;
              &:before{
                display: block;
                position: absolute;
                content: '';
                bottom: -7px;
                left: 20px;
                width: 23px;
                height: 3px;
                background: #2E54D9;
                border-radius: 2px;
              }
            }
          }
        }
      }
      .announcement_box{
        width: 1051px;
        height: 138px;
        background: #DFDFF7;
        border-radius: 10px;
        display: flex;
        padding: 42px 0 0 58px;
        box-sizing: border-box;
        .announcement_input {
          width: 818px;
          height: 42px;
          display: flex;
          background: #FFFFFF;
          border-radius: 15px;
          border: 1px solid #2E54D9;
          &.active {
            width: 740px!important;
          }
          .issue {
            width: 112px;
            height: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ECF1FC;
            border-radius: 15px 0px 0px 15px;
            font-size: 14px;
            font-weight: 400;
            color: #1431A0;
            border-right: 1px solid #2E54D9;
          }
          .input {
            /deep/ .el-input__inner {
              height: 42px;
              border: 0px;
              outline: none;
            }
          }

          .query-btn {
            cursor: pointer;
            width: 68px;
            height: 44px;
            background: #1A3DBF;
            border-radius: 0px 15px 15px 0px;
            background: @blue url("@{imgUrl}common/search_icon.png") no-repeat center;
            margin-top: -1px;
            margin-right: -1px;
            background-size: 32%;

            &:hover {
              background: #1A3DBF url("@{imgUrl}common/search_icon.png") no-repeat center;
            }
          }
        }
        .gaoji{
          position: relative;
          z-index: 2;
          margin-left: 18px;
          cursor: pointer;
          width: 170px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: white;
          border-radius: 15px;
          border: 1px solid #2E54D9;
          font-size: 14px;
          font-weight: 400;
          color: #1A3DBF;
          span{
            display: block;
            width: 75px;
            height: 42px;
            text-align: center;
            line-height: 42px;
            &:nth-of-type(1){
              width: 90px;
            }
          }

          .active{
            display: block;
            width: 95px;
            height: 42px;
            border-radius: 15px;
            background: #1A3DBF;
            text-align: center;
            line-height: 42px;
            color: white;
            &:nth-of-type(2){
              width: 85px;
            }
          }
        }
      }
    }
    .search_content{
      margin: 0px auto 0;
      width: 1200px;
      background: #DFDFF7;
      padding: 47px 0px 50px 75px;
      box-sizing: border-box;
      min-height: 600px;
      ul {
        min-height: 550px;
        li {
          margin-bottom: 24px;
          cursor: pointer;
          width: 1051px;
          height: 156px;
          background: #FFFFFF;
          border-radius: 10px;
          padding: 23px 50px 0 25px;
          box-sizing: border-box;
          p:nth-of-type(1){
            font-size: 14px;
            font-weight: 500;
            color: #434343;
          }
          p:nth-of-type(2){
            margin-top: 8px;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-weight: 400;
            color: #bdbdbd;
          }
          p:nth-of-type(3){
            word-break: break-all;
            margin-top: 8px;
            width: 800px;
            height: 63px;
            font-size: 12px;
            font-weight: 400;
            color: #8e8e8e;
            line-height: 20px;
            display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
            -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
            -webkit-line-clamp: 3; /* 2行，只有 webkit内核支持 */
            word-break: break-all; /* 纯英文换行 */
            overflow: hidden;
          }
          &:hover {
            transform: scale(1.05);
            p:nth-of-type(1){
              color: #191e46;
            }
            p:nth-of-type(2){
              color: #191e46;
            }
            p:nth-of-type(3){
              color: #555a6d;
            }
          }
        }
      }
      .pagination{
        float: right;
        margin-right: 70px;
      }
    }
    .search_contents{
      margin: 2px auto 0;
      width: 1200px;
      background: #DFDFF7;
      padding: 47px 0px 50px 75px;
      box-sizing: border-box;
      min-height: 600px;
      ul {
        min-height: 550px;
        li {
          margin-bottom: 24px;
          cursor: pointer;
          width: 1051px;
          height: 156px;
          background: #FFFFFF;
          border-radius: 10px;
          padding: 20px 0px 0 30px;
          box-sizing: border-box;
          p:nth-of-type(1){
            font-size: 14px;
            font-weight: 500;
            color: #323232;
          }
          p:nth-of-type(2){
            margin-top: 11px;
            display: flex;
            font-size: 12px;
            font-weight: 400;
            color: #aeaeae;
            span{
              width: 222px;
            }
          }
          p:nth-of-type(3){
            word-break: break-all;
            margin-top: 15px;
            /*width: 800px;*/
            display: flex;
            /*height: 63px;*/
            font-size: 12px;
            font-weight: 400;
            color: #484848;
            span{
              margin-right: 12px;
              width: 210px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
          }
          p:nth-of-type(4){
            word-break: break-all;
            margin-top: 15px;
            /*width: 800px;*/
            display: flex;
            /*height: 63px;*/
            font-size: 12px;
            font-weight: 400;
            color: #484848;
            span{
              min-width: 222px;
            }
          }
          .role_item{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          &:hover {
            transform: scale(1.05);
            p:nth-of-type(1){
              color: #1f2772;
            }
            p:nth-of-type(2){
              color: #ababab;
            }
            p:nth-of-type(3){
              color: #56596a;
            }
            p:nth-of-type(4){
              color: #56596a;
            }
          }
        }
      }
      .pagination{
        float: right;
        margin-right: 70px;
      }
    }
    .search_content_announcement {
      margin: 2px auto 0;
      width: 1200px;
      background: #DFDFF7;
      padding: 47px 0px 50px 75px;
      box-sizing: border-box;
      min-height: 600px;
      ul {
        min-height: 550px;
        li {
          margin-bottom: 24px;
          cursor: pointer;
          width: 1051px;
          height: 156px;
          background: #FFFFFF;
          border-radius: 10px;
          padding: 23px 50px 0 25px;
          box-sizing: border-box;
          p:nth-of-type(1){
            font-size: 14px;
            font-weight: 500;
            color: #333333;
          }
          p:nth-of-type(2){
            margin-top: 8px;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            font-weight: 400;
            color: #999999;
          }
          p:nth-of-type(3){
            word-break: break-all;
            margin-top: 8px;
            width: 986px;
            height: 63px;
            font-size: 12px;
            font-weight: 400;
            color: #555555;
            line-height: 20px;
            display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
            -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
            -webkit-line-clamp: 3; /* 2行，只有 webkit内核支持 */
            word-break: break-all; /* 纯英文换行 */
            overflow: hidden;
          }
          &:hover {
            transform: scale(1.05);
            p:nth-of-type(1){
              color: #152E8B;
            }
            p:nth-of-type(2){
              color: #7884B1;
            }
            p:nth-of-type(3){
              color: #1F3072;
            }
          }
        }
      }
      .pagination{
        float: right;
        margin-right: 70px;
      }
    }
  }
</style>
<style lang="less">
  .trademarkAdvanced{
    background: rgb(217, 223, 242);
    margin: 0 auto;
    width: 1051px;
    overflow: hidden;
    min-height: 500px;
    ul {
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid #9ba1b9;
      width: 930px;
      background: #e7ebf7;
      /*min-height: 600px;*/
      border-bottom: none;
      margin: 0 auto;
      li {
        display: flex;
        height: 44px;
        border-bottom: 1px solid #afbace;
        font-size: 12px;
        color: #5e626e;
        position: relative;
        .labelT{
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          background-color: #c1c9dc;
          color: #1b224e;
          padding: 12px 0px;
          border-right: 1px solid #afbace;
          margin-bottom: 0;
          font-size: 14px;
          width: 113px;
        }
        .content {
          width:calc(100% - 129px);
          padding: 20px 50px 0 40px;
          box-sizing: border-box;
          .el-input__inner{
            width: 223px;
          }
          /*display: flex;*/
          /*flex-wrap: wrap;*/
          /*align-items: center;*/
        }
        ._input{
          .el-input__inner{
            width: 167px;
          }
        }
        .block {
          display: inline-flex;
          align-items: center;
          .demonstration{
            display: inline-block;
            min-width: 55px;
          }
          &.active {
            .el-input__inner{
              width: 409px;
            }
          }
        }
      }
    }
    .el-dialog{
      width: 960px;
      height: 366px;
    }
    .el-dialog__body{
      padding: 10px 30px 20px;
    }
    .el-input-group__prepend{
      width: 85px;
      background: #1a3dbf;
      text-align: center;
      color: white;
    }
    .el-input-group__append{
      background: #1a3dbf;
      color: white;
    }
  }
</style>
